import React from 'react';
import Container from '@material-ui/core/Container';
import {
  Typography,
  Box,
  makeStyles,
  Card,
  CardContent,
  MenuList,
  MenuItem,
} from '@material-ui/core';

import * as _ from 'lodash';

// Components
import { Link, graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const useStyles = makeStyles({
  title: {
    fontWeight: 900,
    textTransform: `uppercase`,
  },
  link: {
    boxShadow: `none`,
  },
});

function ChordsByArtist({ pageContext, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const classes = useStyles();
  const { artists } = pageContext;
  const siteTitle = site.siteMetadata.title;

  if (!artists || artists.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo
          title={`Chords by artist in ChordSL.com`}
          description={`Chords by artist in ChordSL.com`}
        />
        <Container maxWidth="lg">
          <Typography variant="h5" component="h1" classname={classes.title}>
            Missing Artists
          </Typography>
        </Container>
      </Layout>
    );
  }

  const sortedArtists = artists.sort();

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={`Chords by artist in ChordSL.com`}
        description={`Chords by artist in ChordSL.com`}
      />
      <Container maxWidth="lg">
        <Box m={4}>
          <Typography variant="h5" component="h1" className={classes.title}>
            Artists
          </Typography>
          <Box m={2}>
            <Card>
              <CardContent>
                <MenuList>
                  {sortedArtists.map(artist => {
                    return (
                      <MenuItem
                        key={artist}
                        component={Link}
                        to={`/chords-by-artist/${_.kebabCase(artist)}`}
                        className={classes.link}
                      >
                        {artist}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}

export default ChordsByArtist;
